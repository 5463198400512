html {
  scroll-behavior: smooth !important;
}
* {
  font-family: 'poppinsregular';
}
p, input {
  font-family: 'poppinsregular' !important;
}
#root {
  /* overflow: hidden; */
  min-width: 1200px;
  margin: 0 auto;
  
}
@font-face {
  font-family: 'saudi_riyal';
  src: url('fonts/saudi_riyal.woff2') format('woff2'),
       url('fonts/saudi_riyal.woff') format('woff'),
       url('fonts/saudi_riyal.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.icon-saudi_riyal::before {
  content: "\e900";
  font-family: 'saudi_riyal' !important;
  font-size: inherit;
  color: inherit;
}
@font-face {
  font-family: "poppinsbold";
  src: url("./fonts/poppins-bold-webfont.woff2") format("woff2"),
    url("./fonts/poppins-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsregular";
  src: url("./fonts/poppins-regular-webfont.woff2") format("woff2"),
    url("./fonts/poppins-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* cairo */
@font-face {
  font-family: "cairosemibold";
  src: url("./fonts/cairo-semibold-webfont.woff2") format("woff2"),
    url("./fonts/cairo-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "cairoregular";
  src: url("./fonts/cairo-regular-webfont.woff2") format("woff2"),
    url("./fonts/cairo-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "cairolight";
  src: url("./fonts/cairo-light-webfont.woff2") format("woff2"),
    url("./fonts/cairo-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "cairoextralight";
  src: url("./fonts/cairo-extralight-webfont.woff2") format("woff2"),
    url("./fonts/cairo-extralight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "cairobold";
  src: url("./fonts/cairo-bold-webfont.woff2") format("woff2"),
    url("./fonts/cairo-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "cairoblack";
  src: url("./fonts/cairo-black-webfont.woff2") format("woff2"),
    url("./fonts/cairo-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* fontello */
@font-face {
  font-family: "fontello";
  src: url("./fonts/fontello/fontello.eot?25654445");
  src: url("./fonts/fontello/fontello.eot?25654445#iefix")
      format("embedded-opentype"),
    url("./fonts/fontello/fontello.woff2?25654445") format("woff2"),
    url("./fonts/fontello/fontello.woff?25654445") format("woff"),
    url("./fonts/fontello/fontello.ttf?25654445") format("truetype"),
    url("./fonts/fontello/fontello.svg?25654445#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?7113535#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-twitter:before {
  content: "\f099";
} /* '' */
.icon-youtube-play:before {
  content: "\f16a";
} /* '' */
.icon-instagram:before {
  content: "\f16d";
} /* '' */
.icon-facebook-squared:before {
  content: "\f308";
} /* '' */
.icon-linkedin-squared:before {
  content: "\f30c";
} /* '' */
/* fontello ends */
* {
  box-sizing: border-box;
  font-family: "poppinsregular";
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  background: transparent;
  font-weight: normal;
  text-decoration: none;
  outline: none;
}
ol,
ul {
  list-style: none;
}
blockquote {
  quotes: none;
}
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}
del {
  text-decoration: line-through;
}
header,
section,
article,
aside,
nav,
footer,
address {
  display: block;
}
body {
  background-color: #ffffff;
  color: #000000;
  font-family: Avenir-Roman, sans-serif;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  color: #000 !important;
  font-size: 12px;
  font-family: initial;
  font-variant: normal;
  line-height: normal;
  background-color: unset;
  font-feature-settings: initial !important;
}
input,
textarea,
select {
  font-family: Arial, Helvetica, sans-serif;
}
a {
  color: #000;
  text-decoration: none;
}
a:hover,
.submit:hover {
  filter: alpha(opacity = 85);
  -moz-opacity: 0.85;
  -khtml-opacity: 0.85;
  opacity: 0.85;
}
p {
  line-height: 1.6em;
}
@media only screen and (min-width: 768px) {
  body {
    font-size: 16px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 768px) {
  body {
    font-size: 15px;
  }
}
@media only screen and (min-width: 479px) {
  body {
    font-size: 12px;
  }
}

/* sweet alert style */
.swal-button {
  background-color: transparent;
  color: #fff;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 24px;
  margin: 0;
  cursor: pointer;
  border: 2px solid transparent;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  outline: none;
}
.swal-button:not([disabled]):hover {
  background-color: transparent;
  color: rgb(255, 255, 255);
  border-radius: 0;
  backdrop-filter: blur(14.5px);
  -webkit-backdrop-filter: blur(14.5px);
  border: 2px solid rgba(177, 173, 173, 0.18);
  outline: none;
}
.swal-button:focus {
  border: 2px solid transparent;
  border-radius: 0;
  box-shadow: none;
}
.swal-title {
  color: rgb(255, 255, 255);
  font-size: 20px;
}
.swal-text {
  color: rgb(255, 255, 255);
}
.swal-title:not(:last-child) {
  margin-bottom: 13px;
  color: rgb(255, 255, 255);
}
.swal-modal {
  color: #fff;
  background-color: #000000;
  border: 1px solid #fff;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.15);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: blur(14.5px);
  -webkit-backdrop-filter: blur(14.5px);
  border: 3px solid rgba(177, 173, 173, 0.05);
  border-radius: 25px;
}
.swal-icon--success:before {
  background: transparent;
}
.swal-icon--success:after {
  background: transparent;
}
.swal-icon--success__hide-corners {
  background: transparent;
}
.swal-icon--success__ring {
  border: 4px solid hsl(98deg 75% 49%);
}
/* sweet alert style ends */
.MuiAutocomplete-option {
  cursor: pointer;
  display: flex;
  outline: 0;
  box-sizing: border-box;
  min-height: 48px;
  align-items: center;
  justify-content: flex-start;
  -webkit-tap-highlight-color: transparent;
  padding: 0.25rem 0.5rem !important;
  font-size: 14px !important;
}
.MuiAutocomplete-listbox {
  padding: 0 !important;
}
.MuiAutocomplete-noOptions {
  padding: 0.25rem 0.5rem !important;
  font-size: 14px;
}
.sun-editor .se-list-layer.se-list-font-size {
  max-height: 115px !important;
}
.sun-editor .se-wrapper{
  min-height:100px !important; 
}
.sun-editor .se-list-layer{
}
.MuiTypography-body1 {
  font-family: "poppinsregular" !important;
  font-size: 12px ;
}
